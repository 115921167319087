import * as Doka from '../lib/doka.esm.min';
import * as FilePond from 'filepond';
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginImageEdit from "filepond-plugin-image-edit";
import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["file", "photo", "uploader"];

  initialize() {
    this.started = false;
    FilePond.registerPlugin(
      FilePondPluginFileEncode,
      FilePondPluginFileValidateType,
      FilePondPluginImageExifOrientation,
      FilePondPluginImagePreview,
      FilePondPluginImageCrop,
      FilePondPluginImageResize,
      FilePondPluginImageTransform,
      FilePondPluginImageEdit
    );
    window.addEventListener('dragenter', event => {
      this.showUploader();
    });
    window.addEventListener('dragleave', event => {
      this.dragLeave(event);
    });
    this.startUploader();
  }

  showUploader(event) {
    if (this.hasPhotoTarget) {
      this.photoTarget.style.display = 'none';
    }
    this.uploaderTarget.style.display = 'block';
  }

  dragLeave(event) {
    if (event.clientX == 0) {
      this.hideUploader();
    }
  }

  hideUploader() {
    if (this.hasPhotoTarget) {
      this.photoTarget.style.display = 'block';
      this.uploaderTarget.style.display = 'none';
    }
  }

  startUploader() {
    this.pond = FilePond.create(
      this.fileTarget,
      {
        labelIdle: this.label,
        imagePreviewHeight: 170,
        imageCropAspectRatio: this.aspect,
        imageResizeTargetWidth: this.width,
        imageResizeTargetHeight: this.height,
        imageEditInstantEdit: true,
        stylePanelLayout:this.layout,
        stylePanelAspectRatio:this.aspect,
        styleLoadIndicatorPosition: 'center bottom',
        styleProgressIndicatorPosition: 'right bottom',
        styleButtonRemoveItemPosition: 'left bottom',
        styleButtonProcessItemPosition: 'right bottom',
        onaddfile:this.fileAdded,
        onremovefile: this.fileRemoved,
        // Use Doka.js as image editor
        imageEditEditor: this.imageEditor()
      }
    );
    this.started = true;
  }

  imageEditor() {
    if (this.circle) {
      return Doka.create({
        utils: ['crop', 'filter', 'color'],
        imagePreviewScaleMode: 'crop',
        cropLimitToImageBounds: false,
        outputCanvasBackgroundColor: '#FFFFFF',
        cropMask: (root, setInnerHTML) => {
          setInnerHTML(root, `<mask id="my-mask">
                  <rect x="0" y="0" width="100%" height="100%" fill="white"/>
                  <circle cx="50%" cy="50%" r="50%" fill="black"/>
              </mask>
              <rect fill="rgba(255,255,255,.3125)" x="0" y="0" width="100%" height="100%" mask="url(#my-mask)"/>
              <circle cx="50%" cy="50%" r="50%" fill="transparent" stroke-width="1" stroke="#fff"/>`);
        }
      })
    }
    return Doka.create({ utils: ['crop', 'filter', 'color'], cropLimitToImageBounds:false, outputCanvasBackgroundColor:'#FFFFFF' });
  }

  fileAdded() {
    document.dispatchEvent(new CustomEvent("image_upload.added"));
  }

  fileRemoved() {
    document.dispatchEvent(new CustomEvent("image_upload.removed"));
  }

  showError(error) {
    this.errorMessageTarget.innerHTML = error;
    this.errorMessageTarget.style.display = 'block';
  }

  hideError() {
    this.errorMessageTarget.innerHTML = '';
    this.errorMessageTarget.style.display = 'none';
  }

  get label() {
    return this.data.get('label') || `Drag & Drop your picture or <span class="filepond--label-action">Browse</span>`
  }

  get layout() {
    //use layout 'integrated' for a square uploader
    return this.data.get('layout') || "compact circle"
  }

  get aspect() {
    if (this.data.get('aspect') === 'none') {
      return null;
    }
    return this.data.get('aspect') || '1:1'
  }

  get width() {
    if (this.data.get('width') == null) {
      return 200;
    }
    return parseInt(this.data.get('width'));
  }

  get height() {
    if (this.data.get('height') == null) {
      return 200;
    }
    return parseInt(this.data.get('height'));
  }

  get circle() {
    if (this.data.get('circle') == null) {
      return false
    }
    return true;
  }

}